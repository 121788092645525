import { Box, Button, Text } from '@medely/ui-kit';
import { useProNavigate } from '@src/contexts';
import React from 'react';

export const BackgroundCheckNotCompleted = () => {
  const { navigate } = useProNavigate();

  function handleClickButton() {
    navigate('BackgroundCheckDisclosure');
  }

  return (
    <>
      <Box my={2}>
        <Text size="m">
          Once you have completed your background check you will be able to use this screen to set
          up direct deposit to receive payments for jobs.
        </Text>
      </Box>
      <Button onClick={handleClickButton} fullWidth>
        Start background check
      </Button>
    </>
  );
};
