import React, { useState } from 'react';
import { Box, Heading, Text } from '@medely/ui-kit';
import SwitchButton from '../../../components/SwitchButton';
import { BankAccountForm } from './components/BankAccountForm';

const PAYOUTMETHODS = [
  { value: true, label: 'Bank account' },
  { value: false, label: 'Debit card' },
];

type Props = {
  onError: (error?: Error) => void;
  onSuccess: () => void;
};

export const DirectDeposit = ({ onError, onSuccess }: Props) => {
  const [isDirectDeposit, setIsDirectDeposit] = useState(true);

  return (
    <Box>
      <Box mt={1}>
        <Heading size="m" data-testId="financial-direct-deposit-title">
          Set up direct deposit
        </Heading>
        <Box mt={2}>
          <Text size="m">
            With our secure payment system, you can choose to connect either your bank account or
            debit card to receive your payouts.
          </Text>
        </Box>
      </Box>
      <Box mt={3}>
        <SwitchButton
          options={PAYOUTMETHODS}
          selected={isDirectDeposit}
          onChange={setIsDirectDeposit}
        />
      </Box>
      <Box mt={3}>
        {isDirectDeposit && <BankAccountForm onError={onError} onSuccess={onSuccess} />}
      </Box>
    </Box>
  );
};
