import type { IStripeAccount } from '@medely/types';
import { Box, Heading, HStack, Icon, Label, Text, VStack } from '@medely/ui-kit';
import useTaxClassifications from '../../../../../hooks/useTaxClassifications';
import React from 'react';
import { INDIVIDUAL_TAX_CLASSIFICATION_ID } from '../../../../../constants/taxes';
import { useProNavigate } from '../../../../../contexts';

type Props = {
  account: IStripeAccount;
};

export const TaxInfoSection = ({ account }: Props) => {
  const { navigate } = useProNavigate();

  if (!account) {
    navigate('PayoutTaxes');
  }

  const {
    company_name,
    first_name,
    last_name,
    address_first,
    address_second,
    address_city,
    address_zip,
    representative,
  } = account;

  const { taxClassifications } = useTaxClassifications();

  const selectedTaxClassificationLabel =
    account?.tax_classification_id === INDIVIDUAL_TAX_CLASSIFICATION_ID
      ? 'Personal account'
      : taxClassifications.find((item) => item.id === account?.tax_classification_id)?.label;

  function handlePressEditIcon() {
    navigate('TaxInformation', { id: account.id });
  }

  return (
    <Box>
      <HStack mt={3} alignItems="center" justifyContent="space-between">
        <Heading size="s">Tax information</Heading>
        <Box onClick={handlePressEditIcon}>
          <Icon name="edit" color="primary" />
        </Box>
      </HStack>

      <VStack my={1}>
        <Label size="m">{company_name || `${first_name} ${last_name}`}</Label>
        <Text size="s" color="text.secondary">
          {selectedTaxClassificationLabel}
        </Text>
      </VStack>

      <VStack my={1}>
        <Text size="m">
          {address_first} {address_second}
        </Text>
        <Text size="m">
          {address_city} {address_zip}
        </Text>
      </VStack>

      {representative && (
        <VStack my={1} gap={0.5}>
          <Label size="s">Company representative</Label>
          <VStack>
            <Text size="s">
              {representative.first_name} {representative.last_name}
            </Text>
          </VStack>
          <VStack>
            <Text size="s">
              {representative.address_first} {representative.address_second}
            </Text>
            <Text size="s">
              {representative.address_city} {representative.address_zip}
            </Text>
          </VStack>

          <Text size="s">{representative.phone}</Text>
          <Text size="s">{representative.email}</Text>
        </VStack>
      )}
    </Box>
  );
};
