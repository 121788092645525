import React from 'react';
import { useGate } from 'statsig-react';
import { getOTRateLabel } from '@medely/base';
import { Box, CircularProgress, HStack, Heading, Text } from '@medely/ui-kit';
import { Button, Dialog, DialogProps } from '@medely/web-components';
import { Calendar, CalendarClock, Clock, MapMarkerAlt } from '@medely/web-components/icons';
import { COVID_REQUIREMENTS, gateNames } from '../../../constants';
import DisplayHelpers from '../../../utils/displayHelpers';
import {
  JobDetailsListItem,
  ProfessionalTerms,
  ShiftDate,
  ShiftLocation,
  CovidProtocol,
  ExtendedRateInfo,
} from '..';
import { IPreBookConfirmationProps } from './interface';

type IPreBookConfirmationDialogProps = DialogProps & IPreBookConfirmationProps;

export const PreBookConfirmationDialog = ({
  open,
  onClose = () => null,
  job,
  bookJob,
  bookingLoading,
  bookJobWithAttestation,
  bookAndAttestLoading,
  userCoordinates,
  onboarding = false,
  disabled = false,
}: IPreBookConfirmationDialogProps) => {
  const { value: showXRInfo } = useGate(gateNames.xrTransparency);

  const covidText =
    COVID_REQUIREMENTS.confirmation[
      job.location.covid_requirement as keyof typeof COVID_REQUIREMENTS.confirmation
    ] ?? '';

  const handleBookButtonAction = () => {
    onboarding
      ? bookJobWithAttestation(
          { jobId: job.id, attest: true },
          {
            onSuccess: () => {
              onClose();
            },
          },
        )
      : bookJob(job.id, {
          onSuccess: () => {
            onClose();
          },
        });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Review shift details"
      data-testid="book-job-confirmation-dialog"
      content={
        <>
          {bookingLoading || bookAndAttestLoading ? (
            <CircularProgress />
          ) : (
            <Box mb={3}>
              <Box py={1.5}>
                <JobDetailsListItem icon={<MapMarkerAlt />} title="Location">
                  <ShiftLocation
                    job={job}
                    bookVariant="pre-book"
                    distanceVariant="local"
                    userCoordinates={userCoordinates}
                  />
                </JobDetailsListItem>
              </Box>
              <Box py={1}>
                <JobDetailsListItem icon={<Calendar />} title="Date">
                  <ShiftDate job={job} displayStartingSoonBadge />
                </JobDetailsListItem>
              </Box>
              <Box py={1}>
                <JobDetailsListItem icon={<Clock />} title="Shift time">
                  <Text size="m">
                    {DisplayHelpers.job.time(job, { scheduledShiftStyle: true })}
                  </Text>
                </JobDetailsListItem>
              </Box>
              {showXRInfo && (
                <Box py={1}>
                  <JobDetailsListItem
                    icon={<CalendarClock />}
                    title={`${getOTRateLabel({ isW2: job.is_w2, abbreviate: false })}s`}
                  >
                    <ExtendedRateInfo job={job} />
                  </JobDetailsListItem>
                </Box>
              )}
              <Box py={2}>
                <ProfessionalTerms />
              </Box>
              {!!job.location.covid_requirement && !!covidText && (
                <CovidProtocol description={covidText} />
              )}
              <Box py={1}>
                <Heading size="s">Additional details</Heading>
                <Box pt={1.5}>
                  <Text size="m">{job?.detail}</Text>
                </Box>
              </Box>
            </Box>
          )}
        </>
      }
      actions={
        <HStack width="100%">
          <Button
            color="primary"
            variant="outlined"
            sx={{ width: '50%', mr: 1 }}
            onClick={onClose}
            data-testid="book-job-confirmation-dialog-close-button"
          >
            Close
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={bookingLoading || bookAndAttestLoading || disabled}
            loading={bookingLoading || bookAndAttestLoading}
            sx={{ width: '50%' }}
            onClick={handleBookButtonAction}
            data-testid="book-job-confirmation-dialog-book-button"
          >
            Book shift
          </Button>
        </HStack>
      }
    />
  );
};
