import type { IStripeAccount } from '@medely/types';
import { Box, Heading, HStack, Icon, Text, VStack } from '@medely/ui-kit';
import { useProNavigate } from '@src/contexts';
import useTaxClassifications from '@src/hooks/useTaxClassifications';
import React from 'react';

type OtherAccountsSectionProps = {
  otherAccounts: IStripeAccount[];
};

type OtherAccountListItemProps = {
  account: IStripeAccount;
};

const OtherAccountListItem = ({ account }: OtherAccountListItemProps) => {
  const { navigate } = useProNavigate();
  const { taxClassifications } = useTaxClassifications();
  const selectedTaxClassificationLabel =
    account?.tax_classification_id === 1
      ? 'Personal account'
      : taxClassifications.find((item) => item.id === account?.tax_classification_id)?.label;

  function handleClickItem() {
    navigate('AccountDetails', { id: account.id });
  }

  return (
    <HStack
      onClick={handleClickItem}
      justifyContent="space-between"
      alignItems="center"
      py={2}
      borderBottomWidth={1}
      borderColor="controls.backing+line"
    >
      <VStack>
        <Text size="m" bold>
          {account.company_name || `${account.first_name} ${account.last_name}`}
        </Text>
        <Text size="s">{selectedTaxClassificationLabel}</Text>
      </VStack>
      <Icon name="chevron-right" />
    </HStack>
  );
};

export const OtherAccountsSection = ({ otherAccounts }: OtherAccountsSectionProps) => {
  const { navigate } = useProNavigate();

  function handleClickPlusIcon() {
    navigate('TaxInformation', {});
  }

  return (
    <>
      <HStack mt={3} mb={1} justifyContent="space-between">
        <Heading size="xs">Other Accounts</Heading>
        <Box onClick={handleClickPlusIcon}>
          <Icon name="plus" color="primary" />
        </Box>
      </HStack>
      {!otherAccounts.length && (
        <Text size="m">Payouts can be delivered to a personal or business account.</Text>
      )}
      {otherAccounts.map((otherAccount) => {
        return <OtherAccountListItem key={otherAccount.id} account={otherAccount} />;
      })}
    </>
  );
};
