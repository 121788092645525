import { Box } from '@medely/ui-kit';
import React from 'react';
import { TaxInfoSection } from './components/TaxInfoSection';
import type { IStripeAccount } from '@medely/types';
import { DirectDepositSection } from './components/DirectDepositSection';

type Props = {
  account: IStripeAccount;
};

export const AccountDetails = ({ account }: Props) => {
  return (
    <Box>
      <TaxInfoSection account={account} />
      <DirectDepositSection account={account} />
    </Box>
  );
};
