import { ITaxForm } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { useQuery } from '@tanstack/react-query';
import { GET_TAX_FORMS } from '@src/graphql';

interface IUseTaxFormReturn {
  taxForms: ITaxForm[];
  isLoading: boolean;
}

const useTaxForms = (): IUseTaxFormReturn => {
  const { request } = useGraphQLRequest();

  const fetchTaxForms = async () => {
    const { taxForms } = await request(GET_TAX_FORMS);
    return taxForms;
  };

  const { data, isLoading } = useQuery(['taxForms'], fetchTaxForms);
  return { taxForms: data ?? [], isLoading };
};

export default useTaxForms;
