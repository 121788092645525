import React from 'react';
import { getOTRateLabel } from '@medely/base';
import { IJob } from '@medely/types';
import { Box, InfoBadge, Text, VStack } from '@medely/ui-kit';
import { HelpCenterLink } from '../helpCenterLink';

type ExtendedRateInfoProps = {
  job: Pick<
    IJob,
    | 'is_w2'
    | 'payout_double_overtime_multiplier'
    | 'payout_max_daily_ot_hours'
    | 'payout_max_daily_regular_hours'
    | 'payout_max_weekly_regular_hours'
    | 'payout_overtime_multiplier'
  >;
};

export const ExtendedRateInfo = ({ job }: ExtendedRateInfoProps) => {
  const is12HrFlatRate = job.payout_max_daily_regular_hours === 12;

  const rateLabel = getOTRateLabel({
    isW2: job.is_w2,
    abbreviate: false,
    capitalize: false,
  });

  const dailyRateSentence = getDailyRateSentence(rateLabel, job);
  const weeklyRateSentence = getWeeklyRateSentence(!!dailyRateSentence, rateLabel, job);

  return (
    <VStack flex={1}>
      {dailyRateSentence && <Text size="m">{dailyRateSentence}</Text>}
      {weeklyRateSentence && <Text size="m">{weeklyRateSentence}</Text>}
      <HelpCenterLink configKey="weekly1099ExtendedRate" text="Learn more" />
      {is12HrFlatRate && (
        <Box alignSelf="flex-start">
          <InfoBadge label="12 hr flat rate" color="info" />
        </Box>
      )}
    </VStack>
  );
};

const getDailyRateSentence = (rateLabel: string, job: ExtendedRateInfoProps['job']) => {
  const hasDailyXR1 =
    job.payout_max_daily_regular_hours < 24 &&
    job.payout_max_daily_ot_hours > 0 &&
    job.payout_overtime_multiplier !== 1;

  const hoursBeforeXR2 = job.payout_max_daily_regular_hours + job.payout_max_daily_ot_hours;
  const hasXR2 = hoursBeforeXR2 < 24 && job.payout_double_overtime_multiplier !== 1;

  if (hasDailyXR1) {
    if (hasXR2) {
      // Has both XR1 and XR2 (8 hour rates in CA)
      return (
        `${job.payout_overtime_multiplier}x daily ${rateLabel} applies for hours ` +
        `${job.payout_max_daily_regular_hours} - ${hoursBeforeXR2} and ` +
        `${job.payout_double_overtime_multiplier}x for hours after ${hoursBeforeXR2} for this shift.`
      );
    } else {
      // Only XR1 (Colorado, Alaska)
      return (
        `${job.payout_overtime_multiplier}x daily ${rateLabel} applies after ` +
        `${job.payout_max_daily_regular_hours} hours for this shift.`
      );
    }
  } else if (hasXR2) {
    // Only XR2 no XR1 (12 hour rates in CA)
    return (
      `${job.payout_double_overtime_multiplier}x daily ${rateLabel} applies after ` +
      `${hoursBeforeXR2} hours for this shift.`
    );
  }

  return null;
};

const getWeeklyRateSentence = (
  hasDailyRate: boolean,
  rateLabel: string,
  job: ExtendedRateInfoProps['job'],
) => {
  const hasWeeklyXR =
    job.payout_max_weekly_regular_hours < 168 && job.payout_overtime_multiplier !== 1;

  if (hasWeeklyXR) {
    if (hasDailyRate) {
      // Has both daily and weekly XR. Shortened sentence.
      return (
        `${job.payout_overtime_multiplier}x weekly ${rateLabel} applies after ` +
        `${job.payout_max_weekly_regular_hours} regular hours at the same facility.`
      );
    } else {
      // Only weekly XR. Longer sentence.
      return (
        `${job.payout_overtime_multiplier}x weekly ${rateLabel} applies after ` +
        `${job.payout_max_weekly_regular_hours} regular hours at the same facility in one calendar week.`
      );
    }
  }

  return null;
};
