import { yup } from '@medely/ui-kit';

export const BankAccountFormSchema = yup.object({
  routing_number: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('Routing number is a required field'),
  account_number: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('Account number is a required field'),
  confirm_account_number: yup
    .string()
    .oneOf([yup.ref('account_number')], 'Account number does not match')
    .required('Confirm account number is a required field'),
});

export type BankAccountFormT = yup.InferType<typeof BankAccountFormSchema>;
