import type { IStripeAccount } from '@medely/types';
import { useProNavigate } from '@src/contexts';
import useStates from '@src/hooks/useStates';
import useTaxClassifications from '@src/hooks/useTaxClassifications';
import useTaxInfo from '@src/hooks/useTaxInfo';
import type { TaxInfo } from '@src/models/settings/tax-info/TaxInfo.schema';
import type { NotificationT } from './types';
import { toPhoneNumber } from '@src/utils/toPhoneNumber';
import L from 'lodash';

type useTaxInfoTabProps = {
  account: IStripeAccount | null;
  notification: NotificationT;
};

export function useTaxInfoTab({ account, notification }: useTaxInfoTabProps) {
  const { taxClassifications } = useTaxClassifications();
  const { addTaxInfo, editTaxInfo } = useTaxInfo();
  const { navigate } = useProNavigate();

  const taxClassificationOptions = taxClassifications
    .filter((taxClassification) => taxClassification.label.toLowerCase() !== 'individual')
    .map((l) => ({ value: l.id, label: l.label }));

  const isEditing = !!account?.id;

  const { states } = useStates();
  const stateOptions = states.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  function handleGoBack() {
    navigate('PayoutTaxes');
  }

  async function onSubmit(values: TaxInfo) {
    const payload = {
      ...values,
      representative: {
        ...values.representative,
        phone: toPhoneNumber(values?.representative?.phone) || '',
      },
    };

    if (isEditing) {
      const editPayload = {
        id: account.id,
        ...payload,
        representative: L.omit(payload.representative, 'id'),
      };

      await editTaxInfo(editPayload, {
        onSuccess: () => {
          handleGoBack();

          notification.success('Tax form is successfully updated');
        },
      });
    } else {
      await addTaxInfo(payload, {
        onSuccess: () => {
          handleGoBack();

          notification.success('New tax form is successfully added');
        },
      });
    }
  }

  return {
    taxClassificationOptions,
    stateOptions,
    onSubmit,
    handleGoBack,
  };
}
