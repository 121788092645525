import { IAccount } from '@medely/types';
import { GET_CURRENT_USER } from '../graphql';
import { useQuery } from '@tanstack/react-query';
import { isSaasAccount, isMarketplaceAccount } from '../utils/account';
import { useFirebaseContext, useConfigurationContext } from '@medely/ui-kit';
import { GqlRequest, useNetworkRequest } from './useNetworkRequest';

const DEFAULT_REFETCH_INTERVAL = 15000;

const getCurrentUser = async (gqlRequest: GqlRequest) => {
  const { me } = await gqlRequest(GET_CURRENT_USER);
  return me;
};

export const useCurrentUser = (
  refetch = false,
): {
  isLoading: boolean;
  currentUser: IAccount | undefined;
  isDeactivated: boolean;
  isApplicant: boolean;
  isSaas: boolean;
  isMarketplace: boolean;
} => {
  const { googleUser, logout } = useFirebaseContext();
  const { adminUrl, facilityUrl } = useConfigurationContext();
  const { gqlRequest } = useNetworkRequest();

  const enabled = !!googleUser;

  const REDIRECT_URLS = {
    administrator: adminUrl,
    facility: facilityUrl,
  };

  const { isLoading, isInitialLoading, data } = useQuery(
    ['currentUser', googleUser?.email],
    (_queryContext) => getCurrentUser(gqlRequest),
    {
      enabled,
      onSuccess: (account: IAccount) => {
        if (account.account_type !== 'professional') {
          // @ts-ignore
          logout?.(REDIRECT_URLS[account.account_type]);
        }
      },
      refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    },
  );

  const isSaas = data ? isSaasAccount(data) : false;
  const isMarketplace = data ? isMarketplaceAccount(data) : false;

  return {
    isLoading: enabled ? isLoading : isInitialLoading,
    currentUser: data,
    isDeactivated: data?.professional?.status === 'deactivated' ?? false,
    isApplicant: data?.professional?.application_status !== 'approved',
    isMarketplace,
    isSaas,
  };
};
