import { ITaxClassification } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { useQuery } from '@tanstack/react-query';
import { GET_TAX_CLASSIFICATIONS } from '@src/graphql';

const useTaxClassifications = (): {
  taxClassifications: ITaxClassification[];
  isLoading: boolean;
} => {
  const { request } = useGraphQLRequest();

  const fetchTaxClassifications = async () => {
    const { taxClassifications } = await request(GET_TAX_CLASSIFICATIONS);
    return taxClassifications;
  };

  const { data = [], isLoading } = useQuery(['taxClassifications'], fetchTaxClassifications);

  return {
    taxClassifications: data || [],
    isLoading,
  };
};

export default useTaxClassifications;
