import { Box, Heading, SingleSelectField, TextField, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '@src/components/FixedFooterLayout';
import React from 'react';
import { useTaxInfoTab } from './useTaxInfoTab';
import { useFormContext } from 'react-hook-form';
import type { IStripeAccount } from '@medely/types';
import type { TaxInfo } from '@src/models/settings/tax-info/TaxInfo.schema';
import { TaxInfoFooter } from './components/TaxInfoFooter';
import { useIsMutating } from '@tanstack/react-query';
import { TaxInfoBusinessLegalRepresentativeInputs } from './components/TaxInfoBusinessLegalRepresentativeInputs';
import type { NotificationT } from './types';

type TaxInfoFormProps = {
  account: IStripeAccount | null;
  notification: NotificationT;
  isIndividual: boolean;
};

export const TaxInfoForm = ({ account, notification, isIndividual }: TaxInfoFormProps) => {
  const { taxClassificationOptions, stateOptions, onSubmit, handleGoBack } = useTaxInfoTab({
    account,
    notification,
  });
  const { handleSubmit } = useFormContext<TaxInfo>();
  const isMutating = useIsMutating();

  const isEditting = !!account?.id;

  return (
    <FixedFooterLayout
      footerContent={
        <TaxInfoFooter
          saveAction={() => handleSubmit(onSubmit)()}
          cancelAction={() => handleGoBack()}
          isLoading={!!isMutating}
        />
      }
    >
      <VStack gap={2}>
        {isIndividual ? (
          <>
            <TextField label="First Name" name="first_name" />
            <TextField label="Last Name" name="last_name" />
            <TextField label="SSN" name="personal_id_number" />
          </>
        ) : (
          <>
            {!isEditting && (
              <SingleSelectField
                label="Tax classification"
                name="tax_classification_id"
                options={taxClassificationOptions}
              />
            )}

            <TextField label="Legal business name" name="company_name" />

            <TextField
              label="EIN"
              name="personal_id_number"
              formatOnChange={(v) => (v || '').replace(/[^0-9]/g, '')}
            />
          </>
        )}

        <Box mt={2}>
          <Heading size="xs" testId="tax-info-home-address-title">
            Taxable Home Address
          </Heading>
        </Box>

        <TextField
          label="Street Address"
          name="address_first"
          testId="tax-info-home-address-first"
        />

        <TextField
          label="Address 2"
          name="address_second"
          testId="tax-info-home-address-second"
          helperText="Unit Number, Suite, Building No."
        />

        <TextField label="City" name="address_city" testId="tax-info-home-address-city" />

        <SingleSelectField label="State" name="state_id" options={stateOptions} />

        <TextField
          label="ZIP Code"
          name="address_zip"
          testId="tax-info-home-address-state"
          formatOnChange={(v) => (v || '').replace(/[^0-9]/g, '')}
        />

        <TaxInfoBusinessLegalRepresentativeInputs stateOptions={stateOptions} />
      </VStack>
    </FixedFooterLayout>
  );
};
