import type { ITaxForm } from '@medely/types';
import {
  Heading,
  VStack,
  SingleSelectField,
  type OptionType,
  Text,
  HStack,
  Box,
  Button,
  CircularProgress
} from '@medely/ui-kit';
import { useProNavigate } from '@src/contexts';
import useTaxForms from '@src/hooks/useTaxForms';
import useTaxFormUrl from '@src/hooks/useTaxFormUrl';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const TaxDocumensSection = () => {
  const { taxForms, isLoading } = useTaxForms();
  const { fetchTaxForms } = useTaxFormUrl();
  const methods = useForm();
  const { openUrl } = useProNavigate();

  const yearValue = methods.watch('year');

  const { refetch, isRefetching } = useQuery(['taxFormsUrl'], () => fetchTaxForms(yearValue), {
    enabled: false,
  });

  const selectOptions: OptionType[] = taxForms.map(({ year, id }) => {
    return { label: String(year), value: id };
  });

  const fetchTaxForm = async (): Promise<ITaxForm> => {
    const response = await refetch();

    return response?.data as unknown as Promise<ITaxForm>;
  };

  const handleDownload = async () => {
    const response = await fetchTaxForm();

    openUrl(response?.tax_form);
  };

  return (
    <VStack mt={3}>
      <Heading size="s">Tax documents</Heading>
      {isLoading ? (
        <CircularProgress />
      ) : selectOptions?.length ? (
        <FormProvider {...methods}>
          <HStack alignItems="flex-end" justifyContent="space-between" mt={2}>
            <Box minWidth={150}>
              <SingleSelectField
                options={selectOptions}
                label="Year"
                name="year"
                placeholder="Year"
              />
            </Box>
            <Button disabled={!yearValue} onClick={handleDownload} loading={isRefetching}>
              Download
            </Button>
          </HStack>
        </FormProvider>
      ) : (
        <VStack mt={1}>
          <Text size="m">No data available.</Text>
        </VStack>
      )}
    </VStack>
  );
};
