import React from 'react';
import { Box, Typography, Button } from '@medely/web-components';
import { useTheme } from '@medely/web-components/themes';

type SwitchButtonProps = {
  selected: boolean;
  options: { label: string; value: boolean }[];
  onChange: (value: boolean) => void;
};

const SwitchButton = ({ selected, onChange, options }: SwitchButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      gap={1}
      sx={{ borderBottom: `solid 1px ${theme.palette.grey[300]}` }}
      data-testid="payout-method-button-select"
      pl={2}
    >
      {options.map(({ value, label }) => {
        const isSelected = value === selected;
        return (
          <Button
            key={label?.toString()}
            onClick={() => onChange(value)}
            sx={{
              borderBottom: isSelected
                ? `solid 4px ${theme.palette.primary.main}`
                : `solid 4px transparent`,
              borderRadius: 0,
            }}
          >
            <Typography
              component="span"
              variant="body2"
              weight="medium"
              color={isSelected ? theme.palette.primary.main : theme.palette.text.secondary}
            >
              {label}
            </Typography>
          </Button>
        );
      })}
    </Box>
  );
};

export default SwitchButton;
