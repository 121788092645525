import React, { useEffect } from 'react';
import { Box, Heading, MultiSelectField, SingleSelectField, useAnalytics } from '@medely/ui-kit';
import { YEARS_OPTIONS } from './constants';
import { useWatch } from 'react-hook-form';
import { IPosition } from '@medely/types';
import { SelectedPosition } from './types';
import { useCurrentUser } from '@src/hooks';

type PositionItemProps = {
  position?: Partial<IPosition>;
  positionIndex: number;
};

const PositionItem = ({ position, positionIndex }: PositionItemProps) => {
  const specialtyOptions =
    position?.specialties
      ?.filter(({ active }) => active)
      .map(({ id, label }) => ({ value: id.toString(), label })) || [];

  return (
    <Box mt={2}>
      <Heading
        size="xs"
        color="text.primary"
        testId="clinical-experience-and-specialties-position-title"
      >
        {position?.display_name}
      </Heading>
      <Box>
        <Box mt={2} testId="clinical-experience-and-specialties-single-select-field">
          <SingleSelectField
            name={`professional_positions.positions[${positionIndex}].years_of_experience`}
            label="Years of experience"
            options={YEARS_OPTIONS}
            parseValue={(value) => value.positions[positionIndex].years_of_experience}
            formatOnChange={(newValue, fieldValue) => {
              const newObject = { ...fieldValue };
              newObject.positions[positionIndex].years_of_experience = newValue;
              return newObject;
            }}
          />
        </Box>
        {!!specialtyOptions.length && (
          <Box mt={2}>
            <MultiSelectField
              name={`professional_positions.positions[${positionIndex}].specialty_ids`}
              label="Specialties"
              options={specialtyOptions}
              testId="clinical-experience-and-specialties-multi-select"
              parseValue={(value) => value.positions[positionIndex].specialty_ids}
              formatOnChange={(newValue, fieldValue) => {
                const newObject = { ...fieldValue };
                newObject.positions[positionIndex].specialty_ids = newValue;
                return newObject;
              }}
              placeholder=""
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ClinicalExperienceAndSpecialties = ({
  allPositions,
}: {
  allPositions: Partial<IPosition>[];
}) => {
  const analytics = useAnalytics();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (!!currentUser) {
      analytics.track('Product Application Positions Background - Viewed', {
        account_id: currentUser?.id,
        context: 'Product Application Positions Background',
        subcontext: null,
        action: 'Viewed',
      });
    }
  }, [currentUser]);

  const professionalPositions = useWatch({ name: 'professional_positions' });
  const selectedPositions: Partial<IPosition>[] =
    professionalPositions?.positions.map((pos: SelectedPosition) =>
      allPositions.find((p) => p.id === pos.id),
    ) || [];

  const getAlphabetizedSelectedPositions = () => {
    const positionsNames = selectedPositions.map((position) => {
      return position.display_name?.toLowerCase();
    });
    const positionInfo = positionsNames.sort().map((positionName) => {
      return selectedPositions.find(
        (position) => position.display_name?.toLowerCase() === positionName,
      );
    });
    return positionInfo;
  };

  const alphabetizedSelectedPositions = getAlphabetizedSelectedPositions();

  return (
    <>
      <Heading size="s" color="text.primary" testId="clinical-experience-and-specialties-title">
        Select years of clinical experience and specialties
      </Heading>
      {alphabetizedSelectedPositions?.map((position, idx) => (
        <PositionItem key={position?.id} position={position} positionIndex={idx} />
      ))}
    </>
  );
};

export default ClinicalExperienceAndSpecialties;
