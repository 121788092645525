import React from 'react';
import { Text } from '@medely/ui-kit';
import { HelpCenterLink } from '../helpCenterLink';

export const EstimatedPayDisclaimer = () => {
  return (
    <Text size="m" color="text.secondary">
      Total estimated pay is based on assumed shift length. Actual pay may be higher or lower based
      on the number of hours actually worked.
      <HelpCenterLink configKey="weekly1099ExtendedRate" text="Learn more" />
    </Text>
  );
};
