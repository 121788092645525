import { IState } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { useQuery } from '@tanstack/react-query';
import { GET_STATES } from '@src/graphql';

type UseStatesResult = {
  isLoading: boolean;
  states: IState[];
};

const useStates = (): UseStatesResult => {
  const { request } = useGraphQLRequest();

  const fetchStates = async () => {
    const { states } = await request(GET_STATES, { input: { orderBy: { name: true } } });
    return states;
  };

  const { data = [], isLoading } = useQuery(['states'], fetchStates);

  return {
    isLoading,
    states: data || [],
  };
};

export default useStates;
