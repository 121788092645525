import useGraphQLRequest from './useGraphQLRequest';
import { GET_TAX_FORM_URL } from '@src/graphql';

interface IUseTaxFormReturn {
  fetchTaxForms: (id: number) => void;
}

const useTaxFormUrl = (): IUseTaxFormReturn => {
  const { request } = useGraphQLRequest();

  const fetchTaxForms = async (id: number) => {
    const { taxForm } = await request(GET_TAX_FORM_URL, { id });

    return taxForm;
  };

  return { fetchTaxForms };
};

export default useTaxFormUrl;
